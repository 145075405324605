/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
window.$ = window.jQuery = require("jquery");
import headerSearch from "./components/header-search";

headerSearch();



document.addEventListener("DOMContentLoaded", function () {

 
  $("#deleteEvent").on("click", function () {
    var id = $(this).attr("data-id");

    doAjaxCalendarBusiness("deleteSlot", id);
  });

  $("#eventModal").on("hide.bs.modal", function (e) {
    $("#eventUpdateForm")[0].reset();
  });

  var SITEURL = window.location.origin;

  $(".dayRepeater").change(function () {
    var slotObject = JSON.parse(localStorage.getItem("slot"));
    var dow = [];

    $("input.dayRepeater:checked").each(function () {
      dow.push($(this).val());
    });

    slotObject.dow = dow;
    localStorage.setItem("slot", JSON.stringify(slotObject));
  });

  $(".colorPicker input").change(function () {
    var slotObject = JSON.parse(localStorage.getItem("slot"));
    slotObject.customColor = $(this).val();
    localStorage.setItem("slot", JSON.stringify(slotObject));
  });

  $("input[type=radio][name=slotTime]").change(function () {
    var slotObject = JSON.parse(localStorage.getItem("slot"));
    slotObject.slotType = $('input[name="slotTime"]:checked').val();
    localStorage.setItem("slot", JSON.stringify(slotObject));
  });

  $("#description").change(function () {
    var slotObject = JSON.parse(localStorage.getItem("slot"));
    slotObject.description = $(this).val();
    localStorage.setItem("slot", JSON.stringify(slotObject));
  });

  $("form#eventUpdateForm").submit(function (e) {
    e.preventDefault();

    var slotObject = JSON.parse(localStorage.getItem("slot"));
    doAjaxCalendarBusiness("saveSlot", JSON.stringify(slotObject));
  });

  function doPreSelectRadios(moment, allDay) {
    var hours = moment.hour();
    if (allDay) {
      $("#all_day").prop("checked", true);
      return "allDay";
    } else if (hours == 8) {
      $("#am_slot").prop("checked", true);
      return "am";
    } else if (hours == 13) {
      $("#pm_slot").prop("checked", true);
      return "pm";
    }
  }


 $('.oneClickSubmit').on('submit', function(event) {
        var $form = $(this);
        var $submitButton = $form.find('[type="submit"]');

        // Store the original button text
        var originalButtonText = $submitButton.html();

        // Change the button text to a spinning icon
        $submitButton.html('Processing...<i class="fas fa-spinner fa-spin"></i>');

        // Disable the submit button
        $submitButton.prop('disabled', true);

        // Ensure the original button text is restored if the form fails to submit (optional)
        $form.on('reset', function() {
        $submitButton.html(originalButtonText);
        $submitButton.prop('disabled', false);
    });
});




  function getSelectedClass(className) {
    $("#" + className).prop("checked", true);
  }

  if (document.getElementById("calendar")) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const initialTimeZone = tz;

    var containerEl = document.getElementById("draggableEvents");

    new FullCalendar.Draggable(containerEl, {
      itemSelector: ".child-event",
      eventData: function (eventEl) {
        return {
          title: eventEl.innerText.trim(),
        };
      },
    });

    $.ajaxSetup({
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
      },
    });
    var calendarEl = document.getElementById("calendar");
    calendar = new FullCalendar.Calendar(calendarEl, {
      businessHours: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        daysOfWeek: [1, 2, 3, 4, 5], // Monday - Thursday
        startTime: "06:00", // a start time (6am in this example)
        endTime: "18:00", // an end time (6pm in this example)
      },
      timeZone: initialTimeZone,
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
      },
      themeSystem: "standard",
      initialView: "dayGridMonth",
      editable: true,
      slotMinTime: "08:00",
      slotMaxTime: "18:00",
      slotDuration: "5:00",
      displayEventEnd: true,
      allDaySlot: true,
      weekends: false,
      displayEventTime: true,
      defaultTimedEventDuration: "4:00:00",
      forceEventDuration: true,
      eventDisplay: "list-item",
      selectable: true,
      droppable: true,
      eventDidMount: function (info) {
        $(info.el).tooltip({
          title: info.event.extendedProps.description || "",
          placement: "top",
          trigger: "hover",
          container: "body",
          html: true,
        });

        var counts = [];
        var date = moment(info.event.start).format("YYYY-DD-MM");
        var events = {
          date: date,
          allDay: info.event.allDay,
          start: info.event.start,
          end: info.event.end,
        };
        counts.push(events);
        localStorage.setItem("counts", JSON.stringify(counts));
      },
      events: {
        url:
          "/provider/get-calendar-events/" +
          window.location.href.substring(
            window.location.href.lastIndexOf("/") + 1
          ),
        method: "GET",
        failure: function (err) {
          alert("there was an error while fetching events!");
        },
      },

      dayCellDidMount: function (info) {
        // const format2 = "YYYY-MM-DD"
        // var dataAttr = moment(info.date).format(format2);
        // console.log(eventCount);
        // var eventCount=0;
        // if(dataAttr == dataAttr){
        //     eventCount++;
        // }
        // var counts  = JSON.parse(localStorage.getItem("counts"));
        // $('*[data-date="'+dataAttr+'"]').html(eventCount);
        // console.log(dataAttr);
        // var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
        // var slotObject  = JSON.parse(localStorage.getItem("slot"));
        // var data = {"date": dataAttr, "locationId":slotObject.locationId};
        // var arr_data = JSON.stringify(data);
        //       $.ajax({
        //         method: "POST",
        //         url: '/ajaxFullCalendar',
        //         data: {_token: CSRF_TOKEN, "data":arr_data, "command":"getCounts"},
        //         success: function(data, textStatus, jqXHR) {
        //            if (data.status =="success"){
        //                 console.log(data)
        //            }else{
        //            console.log("dr");
        //            }
        //         }
        //     });
        // let element = "<div style='position: absolute; left: 4px; top: 4px;'><a href='https://www.w3schools.com/'>TEST-"+info.dayNumberText+"</a></div>";
        // $('#fc-day-span-'+moment(info.date).dayOfYear()).parent().parent().prepend(element);
      },

      eventTimeFormat: {
        // like '14:30:00'
        hour: "numeric",
        meridiem: "short",
      },
      drop: function (arg, obj) {
        const id = arg.draggedEl.getAttribute("data-id");
        const type = arg.draggedEl.getAttribute("data-type");
        const locationId = arg.draggedEl.getAttribute("data-location-id");
        const duration = arg.draggedEl.getAttribute("data-duration");
        const timeFormat = "HH:mm";
        const dateFormat = "dddd, MMMM Do YYYY";
        var momentInt = moment(arg.dateStr, "YYYY-MM-DD");
        const momentStart = momentInt.set("hour", 8);
        var slotStart = moment(momentStart);
        var slotEnd = moment(momentStart).add(duration, "hours");

        var slotType = doPreSelectRadios(momentStart, arg.allDay);

        const slot = {
          startDate: arg.dateStr,
          timeStart: slotStart,
          timeEnd: slotEnd,
          locationId: locationId,
          type: type,
          id: id,
          duration: duration,
          slotType: slotType,
        };
        localStorage.setItem("slot", JSON.stringify(slot));

        $("#eventModalLabel").text(arg.draggedEl.innerText.trim());
        $("#startTime").text(
          slotStart.format(timeFormat) + " - " + slotEnd.format(timeFormat)
        );
        $("#startDate").text(moment(arg.dateStr).format(dateFormat));
        $("#eventModal").modal("show");
        const pika = new Pikaday({
          field: document.getElementById("endDate"),
          firstDay: 1,
          maxDate: new Date("2050-01-01"),
          yearRange: [2023, 2050],
          defaultDate: moment(endDate).toDate(), //14 Mar 2015
          setDefaultDate: true,
          format: "D/MM/YYYY",
          onSelect: function (date) {
            var slotObject = JSON.parse(localStorage.getItem("slot"));
            slotObject.endDate = moment(date).format("YYYY-DD-MM");

            localStorage.setItem("slot", JSON.stringify(slotObject));
          },
        });
        //   arg.remove();
      },
      eventDrop: function (event, delta) {
        var event_start = $.fullCalendar.formatDate(event.start, "Y-MM-DD");
        var event_end = $.fullCalendar.formatDate(event.end, "Y-MM-DD");

        $.ajax({
          url: SITEURL + "/calendar-crud-ajax",
          data: {
            title: event.event_name,
            start: event_start,
            end: event_end,
            id: event.id,
            type: "edit",
          },
          type: "POST",
          success: function (response) {
            displayMessage("Event updated");
          },
        });
      },
      eventReceive: function (event) {
        event.event.remove();
      },
      eventClick: function (info, el, jsEvent, view) {
        timeZone: "UTC";
        var dbId = info.event.id;

        $("#deleteEvent").attr("data-id", dbId);
        var slotObject = JSON.parse(localStorage.getItem("slot"));
        slotObject.eventId = dbId;

        localStorage.setItem("slot", JSON.stringify(slotObject));

        $.get("/provider/get-calendar-event/" + dbId, function (dbEvent) {
          var slotObject = JSON.parse(localStorage.getItem("slot"));
          slotObject.id = dbEvent.encoded_id;
          slotObject.encoded_id = dbEvent.encoded_id;
          slotObject.type = dbEvent.type;
          slotObject.locationId = dbEvent.location_id;
          slotObject.slotType = dbEvent.slot_type;
          slotObject.dow = dbEvent.daysOfWeek;
          slotObject.customColor = dbEvent.className;
          const timeFormat = "HH:mm";
          const dateFormat = "dddd, MMMM Do YYYY";

          slotObject.startDate = dbEvent.start;
          slotObject.endDate =
            dbEvent.endRecur !== null
              ? moment(dbEvent.endRecur).format("YYYY-DD-MM")
              : null;
          localStorage.setItem("slot", JSON.stringify(slotObject));

          console.log(dbEvent);
          var jsDateTime = new Date(dbEvent.start);
          m = moment(dbEvent.start, "YYYY-MM-DD HH:mm");

          doPreSelectRadios(m, dbEvent.allDay);

          $("#description").val(dbEvent.description);
          $("#eventModalLabel").text(dbEvent.title);
          getSelectedClass(dbEvent.className);
          $("#eventModal").modal("show");
          $("#startDate").text(moment(dbEvent.start).format(dateFormat));

          if (dbEvent.daysOfWeek !== null && dbEvent.daysOfWeek !== undefined) {
            dbEvent.daysOfWeek.forEach(function (value) {
              $('input:checkbox[value="' + value + '"]').prop("checked", true);
            });
          }

          var endDate = moment(dbEvent.endRecur, "YYYY-MM-DD");

          const pika = new Pikaday({
            field: document.getElementById("endDate"),
            firstDay: 1,
            maxDate: new Date("2050-01-01"),
            yearRange: [2023, 2050],
            defaultDate: moment(endDate).toDate(), //14 Mar 2015
            setDefaultDate: true,
            format: "D/MM/YYYY",
            onSelect: function (date) {
              var slotObject = JSON.parse(localStorage.getItem("slot"));
              slotObject.endDate = moment(date).format("YYYY-DD-MM");
              localStorage.setItem("slot", JSON.stringify(slotObject));
            },
          });

          $("#eventModal").on("hide.bs.modal", function () {
            pika.destroy();
          });
        });
      },
    });
    calendar.render();

    function getEvents() {
      var events = calendar.getEvents();
      var events_arr = [];
      var results = [];
      events.forEach((item, index) => {
        if (item.allDay === true) {
          slotType = "allDay";
        } else if (moment(item.start).hour() == 9) {
          slotType = "AM";
        } else {
          slotType = "PM";
        }

        events_arr.push({
          id: index,
          date: moment(item.start).format("YYYY-MM-DD"),
          start: moment(item.start).hour(),
          end: moment(item.end).hour(),
          slotType: slotType,
          allDay: item.allDay,
        });
      });

      $.each(events_arr, function (k, v) {
        results[String(v.date)] = getPropIndexByName(
          events_arr,
          "date",
          v.date
        ); //data source, property name, property value
      });

      for (const key in results) {
        theBusiness(`${key}`, `${results[key]}`);
      }

      function theBusiness(date, indexes) {
        var idsArray = indexes.split(",");
        var newArr = [];
        var arr = [];
        $.each(idsArray, function (k, v) {
          arr.push(events_arr[v]);
        });

        newArr[date] = arr;

        for (const key in newArr) {
          console.log(newArr[key]);
          const am = "AM";
          const pm = "PM";
          const allDay = "allDay";
          const amCount = newArr[key].filter(
            (obj) => obj.slotType === am
          ).length;
          const pmCount = newArr[key].filter(
            (obj) => obj.slotType === pm
          ).length;
          const allDayCount = newArr[key].filter(
            (obj) => obj.slotType === allDay
          ).length;

          $("*[data-date=" + date + "] .fc-daygrid-day-bg").html(
            '<span class="badge badge-primary">AM <span class="badge badge-light badge-lg">' +
              amCount +
              '</span></span> <span class="badge badge-info">PM <span class="badge badge-light badge-lg">' +
              pmCount +
              '</span></span><span class="badge badge-dark">All Day <span class="badge badge-light badge-lg">' +
              allDayCount +
              "</span></span>"
          );
        }

        $.each(newArr, function (k, v) {
          const am = "AM";
          const pm = "PM";
          const allDay = "allDay";
          const amCount = newArr.filter((obj) => obj.am === am).length;
          const pmCount = newArr.filter((obj) => obj.pm === pm).length;
          const allDayCount = newArr.filter(
            (obj) => obj.allDay === allDay
          ).length;

          $("*[data-date=" + date + "] .fc-daygrid-day-bg").html(
            '<button type="button" class="btn btn-primary">AM <span class="badge badge-light">' +
              amCount +
              "</span></button>"
          );
        });
      }
    }

    $(document).on("click", "#getevent", function (e) {
      //do stuff here
      getEvents();
    });
  }
});

function getPropIndexByName(data, prop, str) {
  var ret = []; //updated to handle multiple indexes

  $.each(data, function (k, v) {
    if (v[prop] === str) ret.push(k);
  });

  return ret.length ? ret : null;
}

function doAjaxCalendarBusiness(command, data) {
  var CSRF_TOKEN = $('meta[name="csrf-token"]').attr("content");
  $.ajax({
    method: "POST",
    url: "/ajaxFullCalendar",
    data: { _token: CSRF_TOKEN, data: data, command: command },

    success: function (data, textStatus, jqXHR) {
      if (data.status == "success") {
        calendar.refetchEvents();
        $("#eventModal").modal("hide");
        $("#eventUpdateForm")[0].reset();
        // switch(data.event) {
        //    case "delete":
        //      $("#eventModal").modal('hide');
        //      calendar.refetchEvents();
        //      break;
        //    case "save":
        //       $("#eventModal").modal('hide');
        //      calendar.refetchEvents();
        //      break;
        //    default:
        //      $("#eventModal").modal('hide');
        //      calendar.refetchEvents();
        //  }
      } else {
        alert("Error");
      }
    },
  });
}

var myEle = document.getElementById("mainNav");

if (myEle) {
  (function () {
    let scrollPos = 0;
    const mainNav = document.getElementById("mainNav");
    const headerHeight = mainNav.clientHeight;
    window.addEventListener("scroll", function () {
      const currentTop = document.body.getBoundingClientRect().top * -1;
      if (currentTop < scrollPos) {
        // Scrolling Up
        if (currentTop > 0 && mainNav.classList.contains("is-fixed")) {
          mainNav.classList.add("is-visible");
        } else {
          mainNav.classList.remove("is-visible", "is-fixed");
        }
      } else {
        // Scrolling Down
        mainNav.classList.remove(["is-visible"]);
        if (
          currentTop > headerHeight &&
          !mainNav.classList.contains("is-fixed")
        ) {
          mainNav.classList.add("is-fixed");
        }
      }
      scrollPos = currentTop;
    });
  })();
}
$(document).on("click", "#login-dp .dropdown-menu", function (e) {
  e.stopPropagation();
});

(function () {
  var words = ["Club", "Class", "Activity", "Nursery", "Team", "Sport"],
    i = 0;
  setInterval(function () {
    $("#textChangeAnimater").fadeOut(function () {
      $(this)
        .html(words[(i = (i + 1) % words.length)])
        .fadeIn();
    });
  }, 2000);
})();

$(".toggleBookings").click(function () {
  $(this).text() == "Hide Upcoming Bookings"
    ? $(this).text("Show Upcoming Bookings")
    : $(this).text("Hide Upcoming Bookings");
});

$(function () {
  $("body")
    .on("input propertychange", ".floating-label-form-group", function (e) {
      $(this).toggleClass(
        "floating-label-form-group-with-value",
        !!$(e.target).val()
      );
    })
    .on("focus", ".floating-label-form-group", function () {
      $(this).addClass("floating-label-form-group-with-focus");
    })
    .on("blur", ".floating-label-form-group", function () {
      $(this).removeClass("floating-label-form-group-with-focus");
    });
});

// Navigation Scripts to Show Header on Scroll-Up
jQuery(document).ready(function ($) {
  var MQL = 768;

  //primary navigation slide-in effect
  if ($(window).width() > MQL) {
    var headerHeight = $(".navbar-custom").height();
    $(window).on(
      "scroll",
      {
        previousTop: 0,
      },
      function () {
        var currentTop = $(window).scrollTop();
        //check if user is scrolling up
        if (currentTop < this.previousTop) {
          //if scrolling up...
          if (currentTop > 0 && $(".navbar-custom").hasClass("is-fixed")) {
            $(".navbar-custom").addClass("is-visible");
          } else {
            $(".navbar-custom").removeClass("is-visible is-fixed");
          }
        } else if (currentTop > this.previousTop) {
          //if scrolling down...
          $(".navbar-custom").removeClass("is-visible");
          if (
            currentTop > headerHeight &&
            !$(".navbar-custom").hasClass("is-fixed")
          )
            $(".navbar-custom").addClass("is-fixed");
        }
        this.previousTop = currentTop;
      }
    );
  }
});

ScrollReveal().reveal(".scrollreveal", {
  delay: 300,
  duration: 500,
});
ScrollReveal().reveal(".scrollreveal-top", {
  delay: 300,
  origin: "top",
  distance: "50%",
  duration: 500,
  mobile: false,
});
ScrollReveal().reveal(".scrollreveal-left", {
  delay: 300,
  origin: "left",
  distance: "50%",
  duration: 50,
  mobile: false,
});
ScrollReveal().reveal(".scrollreveal-right", {
  delay: 300,
  origin: "right",
  distance: "50%",
  duration: 500,
  mobile: false,
});
ScrollReveal().reveal(".scrollreveal-bottom", {
  delay: 300,
  origin: "bottom",
  distance: "50%",
  duration: 500,
  mobile: false,
});

var winScrollTop = 0;

$.fn.is_on_screen = function () {
  var win = $(window);

  var viewport = {
    top: win.scrollTop(),
    left: win.scrollLeft(),
  };
  viewport.right = viewport.left + win.width();
  viewport.bottom = viewport.top + win.height();

  var bounds = this.offset();
  //bounds.right = bounds.left + this.outerWidth();
  bounds.bottom = bounds.top + this.outerHeight();

  return !(viewport.bottom < bounds.top || viewport.top > bounds.bottom);
};

function parallax() {
  var scrolled = $(window).scrollTop();
  $(".parallax-section").each(function () {
    if ($(this).is_on_screen()) {
      var firstTop = $(this).offset().top;
      var $span = $(this).find(".parallax-item");
      var moveTop = (firstTop - winScrollTop) * 0.3; //speed;
      $span.css("transform", "translateY(" + -moveTop + "px)");
    }
  });
}

$(window).scroll(function (e) {
  winScrollTop = $(this).scrollTop();
  parallax();
});

// How long you want the animation to take, in ms
const animationDuration = 2000;
// Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
const frameDuration = 1000 / 60;
// Use that to calculate how many frames we need to complete the animation
const totalFrames = Math.round(animationDuration / frameDuration);
// An ease-out function that slows the count as it progresses
const easeOutQuad = (t) => t * (2 - t);

// The animation function, which takes an Element
const animateCountUp = (el) => {
  let frame = 0;
  const countTo = parseInt(el.innerHTML, 10);
  // Start the animation running 60 times per second
  const counter = setInterval(() => {
    frame++;
    // Calculate our progress as a value between 0 and 1
    // Pass that value to our easing function to get our
    // progress on a curve
    const progress = easeOutQuad(frame / totalFrames);
    // Use the progress value to calculate the current count
    const currentCount = Math.round(countTo * progress);

    // If the current count has changed, update the element
    if (parseInt(el.innerHTML, 10) !== currentCount) {
      el.innerHTML = currentCount;
    }

    // If we’ve reached our last frame, stop the animation
    if (frame === totalFrames) {
      clearInterval(counter);
    }
  }, frameDuration);
};

// Run the animation on all elements with a class of ‘countup’
const runAnimations = () => {
  const countupEls = document.querySelectorAll(".countup");
  countupEls.forEach(animateCountUp);
};

let animationRunning = false;
$(window).scroll(function (e) {
  winScrollTop = $(this).scrollTop();

  if ($(".countup").is_on_screen() && !animationRunning) {
    runAnimations();
    animationRunning = true;
  }
});
$(document).ready(function () {
  $("#toggleSearch, #MobiletoggleSearch").click(function () {
    $(".header-search-container, .mobile-header-search-container").addClass(
      "toggle-search"
    );
    $(".mobile-header-search-overlay").addClass("active");
    $(".search-btn.dropdown-menu-end").addClass("search-opened");
    $("body").addClass("search-active");
  });

  $(".btn-close-search").click(function () {
    $(".header-search-container, .mobile-header-search-container").removeClass(
      "toggle-search"
    );
    $(".search-btn.dropdown-menu-end").removeClass("search-opened");
    $(".mobile-header-search-overlay").removeClass("active");
    $("body").removeClass("search-active");
  });

  // Variables
  const dropdowns = document.querySelectorAll(
    "#CDrop_main, #CDrop_mobile, #CDrop_search_result, #CDrop_day, #CDrop_age"
  );
  const inputs = document.querySelectorAll("input.custom-select-input");
  const listOfOptions = document.querySelectorAll(".option");
  const body = document.body;

  // Functions
  const toggleDropdown = (event) => {
    const clickedDropdown = event.currentTarget;
    clickedDropdown.classList.toggle("opened");
    event.stopPropagation();
  };

  const selectOption = (event) => {
    const selectedOption = event.currentTarget.textContent;
    const input = event.currentTarget
      .closest(".select-dropdown")
      .querySelector("input.custom-select-input");
    input.value = selectedOption;

    // Manually trigger the change event for the input
    const triggerEvent = new Event("change", {
      bubbles: true,
      cancelable: true,
    });
    input.dispatchEvent(triggerEvent);
  };

  const closeDropdownFromOutside = () => {
    dropdowns.forEach((dropdown) => {
      if (dropdown.classList.contains("opened")) {
        dropdown.classList.remove("opened");
      }
    });
  };

  // Event Listeners
  body.addEventListener("click", closeDropdownFromOutside);

  listOfOptions.forEach((option) => {
    option.addEventListener("click", selectOption);
  });

  dropdowns.forEach((dropdown) => {
    dropdown.addEventListener("click", toggleDropdown);
  });
});

// Date range picker

$("body").on("click", ".dropdown-menu", function (e) {
  $(this).is(".show") && e.stopPropagation();
});

$(".selectall").click(function () {
  let container = $(this).parents("ul.dropdown-menu");
  if ($(this).is(":checked")) {
    $(container).find(".options").prop("checked", true);
    var total = $(container).find(
      'input[type="checkbox"].options:checked'
    ).length;
    $(container)
      .parent()
      .find(".dropdown-text")
      .html("(" + total + ") " + $(container).data("selected-text"));
    // $(container).find(".select-text").html(' Deselect');
  } else {
    $(container).find(".options").prop("checked", false);
    $(container)
      .parent()
      .find(".dropdown-text")
      .html($(container).data("text"));
    // $(container).find(".select-text").html(' Select');
  }
});

$("input[type='checkbox'].options").change(function () {
  let container = $(this).parents("ul.dropdown-menu");
  var a = $(container).find("input[type='checkbox'].options");
  if (a.length == a.filter(":checked").length) {
    $(container).find(".selectall").prop("checked", true);
    // $(container).find(".select-text").html(' Deselect');
  } else {
    $(container).find(".selectall").prop("checked", false);
    // $(container).find(".select-text").html(' Select');
  }
  var total = $(container).find(
    'input[type="checkbox"].options:checked'
  ).length;
  if (total === 0) {
    $(container)
      .parent()
      .find(".dropdown-text")
      .html($(container).data("text"));
  } else {
    $(container)
      .parent()
      .find(".dropdown-text")
      .html("(" + total + ") " + $(container).data("selected-text"));
  }
});
$(document).ready(function () {
  $("input[type='checkbox'].options").trigger("change");
});
